<template>
  <div>
    <div v-if="!isLoadingPayload">
      <div uk-grid>
        <div class="uk-width-1-3@s">
          <div class="uk-inline uk-width-1-1">
            <div
              uk-slideshow="animation: pull; autoplay: true; autoplay-interval: 6000; ratio: 1:1;"
            >
              <div
                class="
                  uk-position-relative
                  uk-visible-toggle
                  uk-border-rounded
                  uk-overflow-hidden
                "
                tabindex="-1"
              >
                <ul class="uk-slideshow-items" v-if="media.length !== 0">
                  <li v-for="mediaItem in media" :key="mediaItem.id">
                    <img :src="mediaItem.url" alt uk-cover />
                  </li>
                </ul>
                <ul class="uk-slideshow-items" v-else>
                  <li>
                    <img
                      :src="api + '/storefronts/' + storefrontId + '/logo'"
                      alt
                      uk-cover
                    />
                  </li>
                </ul>

                <a
                  class="
                    uk-position-center-left uk-position-small uk-hidden-hover
                  "
                  href="#"
                  uk-slidenav-previous
                  uk-slideshow-item="previous"
                ></a>
                <a
                  class="
                    uk-position-center-right uk-position-small uk-hidden-hover
                  "
                  href="#"
                  uk-slidenav-next
                  uk-slideshow-item="next"
                ></a>
              </div>

              <ul
                class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"
              ></ul>
            </div>
            <div class="uk-overlay uk-position-bottom uk-text-right">
              <img
                width="30%"
                :src="api + '/storefronts/' + storefrontId + '/logo'"
                alt
                class="watermark"
              />
            </div>
          </div>
        </div>
        <div class="uk-width-2-3@s">
          <div class="uk-child-width-1-2@m" uk-grid>
            <div>
              <div class="uk-text-large uk-text-bold" test="product-title">
                <div
                  v-if="
                    storefrontStorefront.type === 'redeem' &&
                    product.storefront_redeemables.length &&
                    product.storefront_redeemables[
                      product.storefront_redeemables.length - 1
                    ].display_name
                  "
                >
                  {{
                    product.storefront_redeemables[
                      product.storefront_redeemables.length - 1
                    ].display_name
                  }}
                </div>
                <div v-else class="uk-flex">
                  <div>
                    <div class="uk-text-small">Nombre</div>
                    <div>
                      {{ product.display_name }}
                    </div>
                  </div>
                  <div v-if="['administrator'].includes(authUser.type.slug)">
                    - {{ product.name }}
                  </div>
                  <div class="uk-margin-small-left uk-text-muted">
                    <div class="uk-text-small">Código</div>
                    <div>{{ product.display_code }}</div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  class="uk-button uk-button-primary uk-border-rounded"
                  uk-icon="mail"
                  @click="emailCurrentPage"
                ></button>
                <button
                  class="
                    uk-button
                    uk-button-primary
                    uk-border-rounded
                    uk-margin-small-left
                  "
                  uk-icon="print"
                  @click="printCurrentPage"
                ></button>

                <a
                  class="
                    uk-button
                    uk-button-primary
                    uk-border-rounded
                    uk-margin-small-left
                  "
                  :href="
                    api.slice(0, -4) +
                    '/internal/nova/resources/products/' +
                    product.id
                  "
                  target="_blank"
                  v-if="['administrator'].includes(authUser.type.slug)"
                >
                  Ver en nova
                </a>
              </div>
              <div class="uk-grid-small" uk-grid>
                <div>
                  <div
                    v-if="product.feature_weight != 0"
                    class="
                      uk-badge uk-background-primary
                      text-white
                      uk-margin-top uk-padding-small
                    "
                  >
                    <span uk-icon="heart"></span>
                    <span class="uk-margin-small-left">Destacado</span>
                  </div>
                </div>

                <div v-for="tag in product.tags" :key="tag.id">
                  <router-link
                    :to="
                      tag.is_campaign && tag.slug
                        ? '/' + tag.slug
                        : '/store/search?tags=' + tag.id
                    "
                    class="
                      uk-badge uk-background-primary
                      text-white
                      uk-margin-top uk-padding-small
                    "
                  >
                    {{ tag.name }}
                  </router-link>
                </div>
              </div>
              <div class="uk-margin-top">
                <div class="uk-text-large">Información general</div>
              </div>
              <div v-html="productDescription" test="product-description"></div>
              <div v-if="product.dimensions" class="uk-margin-small-top">
                Dimensiones: {{ product.dimensions }}
              </div>
              <div v-if="product.materials" class="uk-margin-small-top">
                Materiales: {{ product.materials }}
              </div>

              <div v-if="product.variations.length === 0">
                <div class="uk-text-large uk-margin-top">Disponibilidad</div>
                <div v-if="storefrontStorefront.type === 'b2b'">
                  <div v-if="product.stock">
                    {{ product.stock }} unidades ({{
                      formatDatetimeElapsed(product.stock_updated_at)
                    }})
                  </div>
                  <div v-else>Producto descontinuado</div>
                </div>
                <div v-else>
                  {{
                    product.storefront_redeemables[
                      product.storefront_redeemables.length - 1
                    ].current_stock
                  }}
                  unidades
                </div>
              </div>
              <div v-else>
                <div v-if="storefrontStorefront.type === 'b2b'">
                  <div>
                    Stock actualizado el {{ formatDatetime(latestStockUpdate) }}
                  </div>
                  <div>
                    Precios actualizados el
                    {{ formatDatetime(latestPriceUpdate) }}
                  </div>
                </div>
              </div>

              <div
                class="uk-margin-top"
                v-if="['administrator'].includes(authUser.type.slug)"
              >
                <div>
                  {{ product.provider.name }} -
                  {{ product.provider_code }}
                </div>
                <div v-if="product.fob_zip">
                  ZIP Code {{ product.fob_zip }} ({{ product.fob_city }},
                  {{ product.fob_state }}, {{ product.fob_country }})
                </div>
                <div
                  v-if="
                    product.prices_updated_at && product.variations.length === 0
                  "
                >
                  Precio actualizado el
                  {{ formatDatetime(product.prices_updated_at) }}
                </div>
              </div>

              <VariationSelector
                v-if="product.variations.length !== 0"
                :variations="product.variations"
                :canSeeStock="true"
                :canSeePriceUpdates="
                  ['administrator'].includes(authUser.type.slug)
                "
                :setVariation="setVariation"
                :updatingProviderInfo="updatingProviderInfo"
                :quotableIds="model.quotable_ids"
              />
            </div>
            <div>
              <div>
                <div
                  v-if="storefrontStorefront.type === 'b2b'"
                  class="uk-margin-bottom"
                >
                  <div v-if="isLoadingPriceTables" class="uk-text-center">
                    <div uk-spinner></div>
                    <div>Calculando el mejor precio para ti</div>
                  </div>
                  <price-table
                    v-else-if="priceTables"
                    :price-tables="priceTables"
                    :faces="model.faces"
                    :colors="model.colors"
                    :printTechnique="model.printTechnique"
                    :shippingMethod="model.shipping_method"
                  />
                </div>
                <div
                  v-if="storefrontStorefront.type === 'redeem'"
                  class="uk-margin-bottom"
                >
                  <div
                    class="uk-flex uk-flex-middle uk-text-large uk-flex-center"
                  >
                    <div v-if="pointsIcon">
                      <img :src="pointsIcon" alt="" width="50px" />
                    </div>
                    <div class="uk-margin-small-left" test="product-price">
                      <span v-if="product.variations.length === 0">
                        {{
                          formatThousands(
                            product.storefront_redeemables[
                              product.storefront_redeemables.length - 1
                            ].current_price
                          )
                        }}
                      </span>
                      <span v-else>
                        {{
                          formatThousands(
                            activeVariation &&
                              activeVariation.storefront_redeemables[
                                activeVariation.storefront_redeemables.length -
                                  1
                              ].current_price
                          )
                        }}
                      </span>
                      {{ pointsName }}
                    </div>
                  </div>
                  <div
                    v-if="
                      (product.variations.length === 0
                        ? product.storefront_redeemables[
                            product.storefront_redeemables.length - 1
                          ].current_price
                        : activeVariation &&
                          activeVariation.storefront_redeemables[
                            activeVariation.storefront_redeemables.length - 1
                          ].current_price) <= pointsBalance
                    "
                    class="uk-text-center"
                  >
                    ¡Tienes los {{ pointsName }} disponibles para canjear este
                    producto!
                  </div>
                  <div class="uk-text-center" v-else>
                    No tienes los {{ pointsName }} disponibles para canjear este
                    producto
                  </div>
                </div>
                <div
                  class="
                    uk-card uk-card-primary uk-padding-small uk-border-rounded
                  "
                >
                  <div class="uk-text-center uk-text-bold">
                    <span v-if="storefrontStorefront.type === 'b2b'"
                      >Agregar a mi cotización</span
                    >
                    <span v-if="storefrontStorefront.type === 'redeem'"
                      >Agregar a mi carrito</span
                    >
                  </div>
                  <ValidationObserver v-slot="{ invalid }">
                    <div class="uk-grid-small" uk-grid>
                      <div class="uk-width-1-1">
                        <ValidationProvider
                          v-if="!['client'].includes(authUser.type.slug)"
                          name="client"
                          rules=""
                          v-slot="{ errors }"
                        >
                          <div>Cliente</div>
                          <v-select
                            id="client-select"
                            v-model="model.user_id"
                            class="uk-input uk-border-rounded"
                            :options="clients"
                            :reduce="(client) => client.id"
                            :getOptionLabel="
                              (option) =>
                                option.first_name +
                                ' ' +
                                option.last_name +
                                ' - ' +
                                option.business.name
                            "
                          ></v-select>
                          <div>
                            {{ errors.length !== 0 ? errors[0] : "" }}
                          </div>
                        </ValidationProvider>
                      </div>
                      <div
                        v-if="product.variations.length !== 0"
                        class="uk-width-1-1"
                      >
                        <div v-if="Object.keys(model.quotable_ids).length">
                          <div
                            v-for="quotableId in Object.keys(
                              model.quotable_ids
                            )"
                            :key="'quotable-id-' + quotableId"
                            class="uk-margin-small-bottom"
                          >
                            <ValidationProvider
                              v-slot="{ errors }"
                              class="uk-flex uk-flex-middle"
                              :rules="variationQuantityValidator(quotableId)"
                            >
                              <div class="uk-width-1-3">
                                <div v-if="storefrontStorefront.type === 'b2b'">
                                  Cantidad de
                                  {{ constructFullVariationName(quotableId) }}
                                </div>
                                <div v-else>
                                  {{
                                    constructFullVariationName(
                                      allVariations.filter((variation) =>
                                        variation.storefront_redeemables.some(
                                          (storefrontRedeemable) =>
                                            storefrontRedeemable.id ==
                                            quotableId
                                        )
                                      )[0].id
                                    )
                                  }}
                                </div>
                              </div>
                              <div>
                                <input
                                  test="variation-quantity-input"
                                  class="
                                    uk-input uk-border-rounded uk-width-1-1
                                  "
                                  type="number"
                                  name="Cantidad"
                                  v-model="model.quotable_ids[quotableId]"
                                />
                                <div>{{ errors[0] }}</div>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div v-else>
                          Agrega variaciones desde el selector de variaciones
                          haciendo click en <span uk-icon="plus"></span>
                        </div>
                      </div>
                      <div
                        :class="{
                          'uk-width-1-2@m uk-width-1-4@xl':
                            storefrontStorefront.type === 'b2b',
                          'uk-width-1-1':
                            storefrontStorefront.type === 'redeem',
                        }"
                        v-if="product.variations.length === 0"
                      >
                        <ValidationProvider
                          name="Cantidad"
                          :rules="quantityValidator"
                          v-slot="{ errors }"
                        >
                          <div>Cantidad</div>
                          <input
                            test="product-quantity-input"
                            class="uk-input uk-border-rounded uk-width-1-1"
                            type="number"
                            name="Cantidad"
                            v-model="
                              model.quotable_ids[
                                storefrontStorefront.type === 'b2b'
                                  ? product.id
                                  : product.storefront_redeemables[
                                      product.storefront_redeemables.length - 1
                                    ].id
                              ]
                            "
                          />
                          <div>{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div
                        class="uk-width-1-2@m uk-width-1-4@xl"
                        v-if="storefrontStorefront.type === 'b2b'"
                      >
                        <ValidationProvider
                          name="Técnica de impresión"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div>Técnica de impresión</div>
                          <select
                            name="Técnica de impresión"
                            v-model="model.printTechnique"
                            class="uk-select uk-border-rounded"
                          >
                            <option
                              v-for="(printTechnique, index) in printTechniques"
                              :key="'printTechnique-' + index"
                              :value="printTechnique"
                            >
                              <span v-if="printTechnique === 'serigraphy'"
                                >Serigrafía</span
                              >
                              <span v-if="printTechnique === 'pad-printing'"
                                >Tampografía</span
                              >
                              <span v-if="printTechnique === 'sublimation'"
                                >Sublimación</span
                              >
                            </option>
                          </select>
                          <div>
                            {{ errors.length !== 0 ? errors[0] : "" }}
                          </div>
                        </ValidationProvider>
                      </div>
                      <div
                        class="uk-width-1-2@m uk-width-1-4@xl"
                        v-if="storefrontStorefront.type === 'b2b'"
                      >
                        <ValidationProvider
                          name="Rostros de Impresión"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div>Rostros de Impresión</div>
                          <select
                            name="Rostros de Impresión"
                            v-model="model.faces"
                            class="uk-select uk-border-rounded"
                          >
                            <option
                              v-for="quantity in Array.from(
                                Array(maxPrintableFaces).keys()
                              ).map((i) => i + 1)"
                              :key="'faces-' + quantity"
                              :value="quantity"
                            >
                              {{ quantity }}
                            </option>
                          </select>
                          <div>
                            {{ errors.length !== 0 ? errors[0] : "" }}
                          </div>
                        </ValidationProvider>
                      </div>
                      <div
                        class="uk-width-1-2@m uk-width-1-4@xl"
                        v-if="storefrontStorefront.type === 'b2b'"
                      >
                        <ValidationProvider
                          name="Colores de Impresión"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div>Colores de Impresión</div>
                          <select
                            name="Colores de Impresión"
                            v-model="model.colors"
                            class="uk-select uk-border-rounded"
                          >
                            <option>1</option>
                            <option>2</option>
                          </select>
                          <div>
                            {{ errors.length !== 0 ? errors[0] : "" }}
                          </div>
                        </ValidationProvider>
                      </div>
                      <div
                        class="uk-width-1-1"
                        v-if="storefrontStorefront.type === 'b2b'"
                      >
                        <ValidationProvider
                          name="Notas"
                          rules
                          v-slot="{ errors }"
                        >
                          <div>Notas</div>
                          <textarea
                            class="uk-textarea uk-border-rounded"
                            rows="2"
                            name="Notas"
                            v-model="model.notes"
                          />
                          <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div
                        class="uk-margin"
                        v-if="!invalid && storefrontStorefront.type === 'b2b'"
                      >
                        <div v-if="isLoadingPrice" class="uk-text-center">
                          <span uk-spinner></span>
                        </div>
                        <div v-else-if="quotedPrices">
                          <div>Opciones de entrega</div>
                          <div
                            class="uk-margin-small-top uk-grid-small"
                            uk-grid
                          >
                            <div
                              v-for="quotedPrice in Object.keys(quotedPrices)"
                              :key="quotedPrice"
                            >
                              <div
                                class="
                                  uk-card-default
                                  uk-padding-small
                                  uk-border-rounded
                                "
                                @click="selectShippingMethod(quotedPrice)"
                              >
                                <div class="uk-flex uk-flex-between">
                                  <div class="uk-text-bold">
                                    {{
                                      {
                                        local: "1 semana",
                                        courier: "2 a 3 semanas",
                                        airway: "4 a 5 semanas",
                                        ship: "6 a 7 semanas",
                                      }[quotedPrice]
                                    }}
                                  </div>
                                  <div
                                    v-if="quotedPrices[quotedPrice].selected"
                                    uk-icon="check"
                                  ></div>
                                </div>
                                <div>
                                  <span class="uk-text-bold"
                                    >Precio unitario:</span
                                  >
                                  {{
                                    toCurrency(quotedPrices[quotedPrice].price)
                                  }}
                                </div>
                                <div>
                                  <span class="uk-text-bold"
                                    >Precio total:</span
                                  >
                                  {{
                                    toCurrency(
                                      quotedPrices[quotedPrice].price *
                                        model.quantity
                                    )
                                  }}
                                </div>
                                <div>
                                  <span class="uk-text-bold"
                                    >Entrega estimada:</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        uk-text-center
                        uk-margin-small-top
                        uk-flex
                        uk-flex-center
                      "
                    >
                      <div>
                        <button
                          test="add-to-cart-button"
                          class="uk-button uk-button-primary uk-border-rounded"
                          @click="addProduct"
                          :disabled="isLoading || !canAddProduct || invalid"
                        >
                          <span v-if="isLoading" uk-spinner></span>
                          <span v-else>
                            <span v-if="storefrontStorefront.type === 'b2b'"
                              >Agregar a mi cotización</span
                            >
                            <span v-if="storefrontStorefront.type === 'redeem'"
                              >Agregar a mi carrito</span
                            >
                          </span>
                        </button>
                      </div>
                    </div>
                    <div
                      class="
                        uk-text-center
                        uk-margin-small-top
                        uk-flex
                        uk-flex-center
                      "
                      v-if="['administrator'].includes(authUser.type.slug)"
                    >
                      <div>
                        <button
                          class="uk-button uk-button-primary uk-border-rounded"
                          @click="getPriceReview"
                          :disabled="isLoading || !canAddProduct || invalid"
                        >
                          <span v-if="isLoading" uk-spinner></span>
                          <span v-else>Revisar proceso de cálculo</span>
                        </button>
                      </div>
                    </div>
                    <div v-if="priceReview">
                      <div
                        v-for="(line, index) in priceReview"
                        :key="'priceReviewLine-' + index"
                      >
                        {{ line }}
                      </div>
                    </div>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-margin-top" v-if="storefrontStorefront.type === 'b2b'">
        <div class="uk-text-large">Productos relacionados</div>
        <div
          class="
            uk-margin-small-top
            uk-child-width-1-2@s
            uk-child-width-1-4@m
            uk-child-width-1-6@l
            uk-grid-medium
            uk-grid-match
          "
          v-if="!fetchingRelatedProducts"
          uk-grid
        >
          <div v-for="product in displayRelatedProducts" :key="product.id">
            <ProductCard :product="product" />
          </div>
        </div>
        <div v-else class="uk-text-center">
          <span uk-spinner></span>
        </div>
      </div>
    </div>
    <div v-else class="uk-text-center">
      <div uk-spinner></div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import PriceTable from "../Utils/PriceTable";
import VariationSelector from "../Utils/VariationSelector";
import ProductCard from "../Utils/ProductCard";
import { mapGetters } from "vuex";
import moment from "moment";

const getAllVariations = (variations) => {
  return [
    ...variations,
    ...variations
      .map((variation) => getAllVariations(variation.children))
      .flat(),
  ];
};

export default {
  name: "StoreShow",

  components: {
    PriceTable,
    VariationSelector,
    ProductCard,
  },

  data() {
    return {
      clients: [],
      isLoadingPayload: true,
      priceReview: null,
      updatingProviderInfo: false,
      fetchingRelatedProducts: false,
      model: {
        faces: null,
        colors: null,
        printTechnique: null,
        quotable_id: null,
        quotable_type: null,
        notes: null,
        user_id: null,
        quotable_ids: {},
      },
      activeVariation: null,
      product: null,
      priceTables: null,
      isLoading: false,
      isLoadingPrice: false,
      quotedPrices: {},
      isLoadingPriceTables: false,
      quantityTimer: null,
      relatedProducts: [],
      variationPriceTables: {},
    };
  },

  mounted() {
    this.$root.$on("add-quotable", (quotableId) => {
      if (!(quotableId in this.model.quotable_ids)) {
        this.$set(this.model.quotable_ids, quotableId, 1);
      }
    });
    this.$root.$on("remove-quotable", (quotableId) => {
      this.$delete(this.model.quotable_ids, quotableId);
    });
    this.$root.$on("set-shipping-method", (shippingMethod) => {
      this.model.shipping_method = shippingMethod;
    });
  },

  computed: {
    ...mapGetters([
      "authUser",
      "storefrontStorefront",
      "pointsIcon",
      "pointsName",
      "pointsBalance",
    ]),
    currentMinQuantity() {
      if (this.priceTables) {
        return Math.min(
          ...Object.keys(Object.values(this.priceTables)[0]).map((quantity) =>
            parseInt(quantity)
          )
        );
      }

      return 0;
    },
    quantityValidator() {
      let validator = "required|numeric";
      if (this.storefrontStorefront.type === "b2b") {
        validator +=
          this.currentMinQuantity &&
          this.currentInventory < this.currentMinQuantity
            ? "|max_value:" + this.currentInventory
            : (this.currentMinQuantity
                ? "|min_value:" + this.currentMinQuantity
                : "") +
              "|max_value:" +
              this.currentInventory;
      } else {
        validator +=
          "|min_value:1|max_value:" +
          this.product.storefront_redeemables[
            this.product.storefront_redeemables.length - 1
          ].current_stock;
      }

      return validator;
    },
    productDescription() {
      let description =
        this.storefrontStorefront.type === "redeem" &&
        this.product.storefront_redeemables.length &&
        this.product.storefront_redeemables[
          this.product.storefront_redeemables.length - 1
        ].description
          ? this.product.storefront_redeemables[
              this.product.storefront_redeemables.length - 1
            ].description
          : this.product.description;

      description = description || "";

      return description.replace(/(\\r)*\\n/g, "<br><br>");
    },
    allVariations() {
      return getAllVariations(this.product.variations);
    },
    latestStockUpdate() {
      return moment.max(
        this.allVariations
          .map((variation) => variation.stock_updated_at)
          .filter((date) => date)
          .map((date) => moment(date))
      );
    },
    latestPriceUpdate() {
      return moment.max(
        this.allVariations
          .map((variation) => variation.prices_updated_at)
          .filter((date) => date)
          .map((date) => moment(date))
      );
    },
    allVariationsById() {
      return this.allVariations.reduce((accum, variation) => {
        accum[variation.id] = variation;

        return accum;
      }, {});
    },
    allStorefrontRedeemablesById() {
      return [
        ...this.allVariations.map(
          (variation) => variation.storefront_redeemables
        ),
      ]
        .flat()
        .reduce((accum, storefrontRedeemable) => {
          accum[storefrontRedeemable.id] = storefrontRedeemable;

          return accum;
        }, {});
    },
    displayRelatedProducts() {
      return this.relatedProducts.map((product) => {
        const startingAtQuantity = product.prices
          ? Math.min(...Object.keys(product.prices))
          : null;
        const startingAtPrice = startingAtQuantity
          ? product.prices[startingAtQuantity]
          : null;
        return {
          ...product,
          main_image: this.extractImage(product),
          startingAtPrice,
          startingAtQuantity,
        };
      });
    },
    canAddProduct() {
      return Object.values(this.model.quotable_ids).some(
        (quantity) => parseInt(quantity) > 0
      );
    },
    priceTableWatchableInfo() {
      return {
        printTechnique: this.model.printTechnique,
        faces: this.model.faces,
        colors: this.model.colors,
        activeVariation: this.activeVariation ? this.activeVariation.id : null,
      };
    },
    quantity() {
      return this.model.quantity;
    },
    media() {
      switch (this.storefrontStorefront.type) {
        case "b2b":
          if (this.product.variations.length !== 0 && this.activeVariation) {
            return [...this.activeVariation.media, ...this.product.media];
          }
          return this.product.media;
        case "redeem":
          if (this.product.variations.length !== 0 && this.activeVariation) {
            return [
              ...(this.activeVariation.storefront_redeemables.length
                ? this.activeVariation.storefront_redeemables[
                    this.activeVariation.storefront_redeemables.length - 1
                  ].media
                : []),
              ...(this.product.storefront_redeemables.length
                ? this.product.storefront_redeemables[
                    this.product.storefront_redeemables.length - 1
                  ].media
                : []),
            ];
          }
          return this.product.storefront_redeemables.length
            ? this.product.storefront_redeemables[
                this.product.storefront_redeemables.length - 1
              ].media
            : [];
        default:
          return [];
      }
    },
    printTechniques() {
      return this.getFieldValue("print-technique", ["serigraphy"]);
    },
    maxPrintableFaces() {
      return parseInt(this.getFieldValue("max-printable-faces", 1));
    },
    defaultPrintableFaces() {
      return this.getFieldValue("default-printable-faces", 1);
    },
    maxPrintableColors() {
      return this.getFieldValue("max-printable-colors", 2);
    },
    defaultPrintableColors() {
      return this.getFieldValue("default-printable-colors", 1);
    },
    currentInventory() {
      if (this.product.variations.length !== 0) {
        if (this.activeVariation) {
          return this.activeVariation.stock;
        } else {
          return 0;
        }
      } else {
        return this.product.stock;
      }
    },
  },

  beforeMount() {
    this.axios
      .get("/store/" + this.$route.params.id)
      .then(({ data }) => {
        this.clients = data.clients;
        this.product = data.product;
        this.model = {
          quantity: null,
          faces: null,
          colors: null,
          printTechnique: null,
          quotable_id:
            this.product.variations.length === 0
              ? this.storefrontStorefront.type === "b2b"
                ? this.product.id
                : this.product.storefront_redeemables[
                    this.product.storefront_redeemables.length - 1
                  ].id
              : null,
          quotable_type:
            this.storefrontStorefront.type === "b2b"
              ? this.product.variations.length === 0
                ? "App\\Models\\Product"
                : "App\\Models\\ProductVariation"
              : "App\\Models\\StorefrontRedeemable",
          notes: null,
          user_id: null,
          shipping_method: null,
          quotable_ids: {},
        };
        if (this.product.variations.length === 0) {
          console.log("Wrongfully setting the product ID");
          this.$set(this.model.quotable_ids, this.product.id, 0);
        }
        this.priceTables = this.product.prices;
        this.withoutWatchers(() => {
          this.model.faces = this.defaultPrintableFaces;
          this.model.colors = this.defaultPrintableColors;
          this.model.printTechnique = this.printTechniques[0];
        });
        if (this.storefrontStorefront.type === "b2b") {
          this.updatingProviderInfo = true;
          this.axios
            .get("/store/" + this.product.id + "/request-inventory-update")
            .then(({ data: { product } }) => {
              this.product = product;
            })
            .finally(() => {
              this.updatingProviderInfo = false;
            });
          this.fetchingRelatedProducts = true;
          this.axios
            .get("/store/" + this.product.id + "/related-products")
            .then(({ data: { relatedProducts } }) => {
              this.relatedProducts = relatedProducts;
            })
            .finally(() => {
              this.fetchingRelatedProducts = false;
            });
        }
      })
      .finally(() => {
        this.isLoadingPayload = false;
      });
  },

  methods: {
    variationQuantityValidator(quotableId) {
      let validator = "required|numeric";
      if (this.storefrontStorefront.type === "b2b") {
        return validator + "|min_value:1";
      } else {
        const storefrontRedeemable =
          this.allStorefrontRedeemablesById[quotableId];
        return (
          validator +
          "|min_value:1|max_value:" +
          storefrontRedeemable.current_stock
        );
      }
    },
    constructFullVariationName(variationId) {
      return (
        (this.allVariationsById[variationId].product_variation_id
          ? this.constructFullVariationName(
              this.allVariationsById[variationId].product_variation_id
            ) + ": "
          : "") + this.allVariationsById[variationId].value
      );
    },
    extractImage(product) {
      if (product.variations && product.variations.length !== 0) {
        const variationImage = product.variations
          .map((variation) => this.extractImage(variation))
          .filter((image) => image);
        if (variationImage.length !== 0) {
          return variationImage[0];
        }
      }
      if (product.children && product.children.length !== 0) {
        const childrenImage = product.children
          .map((children) => this.extractImage(children))
          .filter((image) => image);
        if (childrenImage.length !== 0) {
          return childrenImage[0];
        }
        return null;
      }
      if (
        this.storefrontStorefront.type === "b2b" &&
        product.media.length !== 0
      ) {
        return product.media[0];
      }
      if (
        this.storefrontStorefront.type === "redeem" &&
        product.storefront_redeemables.length !== 0 &&
        product.storefront_redeemables[0].media.length !== 0
      ) {
        return product.storefront_redeemables
          .map((storefrontRedeemable) => storefrontRedeemable.media)
          .flat()[0];
      }
      return null;
    },
    withoutWatchers(cb) {
      const watchers = this._watchers.map((watcher) => ({
        cb: watcher.cb,
        sync: watcher.sync,
      }));

      for (let index in this._watchers) {
        this._watchers[index] = Object.assign(this._watchers[index], {
          cb: () => null,
          sync: true,
        });
      }

      cb();

      for (let index in this._watchers) {
        this._watchers[index] = Object.assign(
          this._watchers[index],
          watchers[index]
        );
      }
    },
    selectShippingMethod(shippingMethod) {
      Object.keys(this.quotedPrices).forEach((shippingMethod) => {
        this.quotedPrices[shippingMethod].selected = false;
      });
      this.quotedPrices[shippingMethod].selected = true;
      this.model.shipping_method = shippingMethod;
    },
    getFieldValue(slug, defaultValue) {
      const filledField = this.product.filled_fields.find(
        (filledField) => filledField.field.slug === slug
      );
      return filledField ? filledField.value : defaultValue;
    },
    emailCurrentPage() {
      window.location.href =
        "mailto:?subject=Revisa este producto que he encontrado en Gimmick!&body=He encontrado un producto en Gimmick que te puede interesar, puedes acceder a el utilizando el siguiente link: \n\n" +
        escape(window.location.href);
    },
    printCurrentPage() {
      console.log("Should print current view");
    },
    setVariation(variation) {
      this.activeVariation = variation;
      this.model.quotable_id =
        this.storefrontStorefront.type === "b2b"
          ? variation
            ? variation.id
            : null
          : variation.storefront_redeemables[
              variation.storefront_redeemables.length - 1
            ].id;
    },
    addProduct() {
      this.isLoading = true;
      this.axios
        .post("/quotes/product", this.model)
        .then(() => {
          UIkit.notification({
            message:
              '<span uk-icon="icon: check"></span> Se han agregado ' +
              Object.values(this.model.quotable_ids).reduce(
                (accum, quantity) => accum + parseInt(quantity),
                0
              ) +
              " unidades de " +
              (this.storefrontStorefront.type === "b2b"
                ? this.product.display_code + " - " + this.product.display_name
                : this.product.storefront_redeemables[0].display_name) +
              " a tu " +
              (this.storefrontStorefront.type === "b2b"
                ? "cotización"
                : "carrito") +
              ".",
            pos: "bottom-left",
          });
          this.$router.push("/quotes");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    refetchData() {
      this.isFetching = true;
      this.axios
        .get(window.location.pathname + "?dataOnly=true")
        .then(({ data: { product } }) => {
          this.product = product;
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    getPrice() {
      if (
        this.model.quantity &&
        (this.currentMinQuantity
          ? this.model.quantity >= this.currentMinQuantity
          : true) &&
        this.model.faces &&
        this.model.colors
      ) {
        this.isLoadingPrice = true;
        this.axios
          .get("/store/pricing", {
            params: {
              quantity: this.model.quantity,
              faces: this.model.faces,
              colors: this.model.colors,
              print_technique: this.model.printTechnique,
              quotable_type:
                this.storefrontStorefront.type === "b2b"
                  ? this.activeVariation
                    ? "App\\Models\\ProductVariation"
                    : "App\\Models\\Product"
                  : "App\\Models\\StorefrontRedeemable",
              quotable_id: this.activeVariation
                ? this.storefrontStorefront.type === "b2b"
                  ? this.activeVariation.id
                  : this.activeVariation.storefront_redeemables[
                      this.activeVariation.storefront_redeemables.length - 1
                    ].id
                : this.storefrontStorefront.type === "b2b"
                ? this.product.id
                : this.product.storefront_redeemables[
                    this.product.storefront_redeemables.length - 1
                  ].id,
              user_id: this.model.user_id,
            },
          })
          .then(({ data: { quotedPrices } }) => {
            this.quotedPrices = Object.keys(quotedPrices).reduce(
              (accum, shippingMethod, index) => {
                accum[shippingMethod] = {
                  price: quotedPrices[shippingMethod],
                  selected: index === 0,
                };

                if (index === 0) {
                  this.model.shipping_method = shippingMethod;
                }

                return accum;
              },
              {}
            );
            // this.quotedPrices = data;
          })
          .catch((error) => {
            console.log("error", error);
          })
          .finally(() => {
            this.isLoadingPrice = false;
          });
      }
    },
    getPriceReview() {
      if (
        Object.values(this.model.quotable_ids).length &&
        Object.values(this.model.quotable_ids)[0] > 0 &&
        // this.model.quantity >= this.currentMinQuantity &&
        this.model.faces &&
        this.model.colors
      ) {
        this.isLoadingPrice = true;
        this.axios
          .get("/store/price-review", {
            params: {
              quantity: Object.values(this.model.quotable_ids)[0],
              faces: this.model.faces,
              colors: this.model.colors,
              print_technique: this.model.printTechnique,
              quotable_type:
                this.storefrontStorefront.type === "b2b"
                  ? this.activeVariation
                    ? "App\\Models\\ProductVariation"
                    : "App\\Models\\Product"
                  : "App\\Models\\StorefrontRedeemable",
              quotable_id: this.activeVariation
                ? this.storefrontStorefront.type === "b2b"
                  ? Object.keys(this.model.quotable_ids)[0]
                  : this.activeVariation.storefront_redeemables[
                      this.activeVariation.storefront_redeemables.length - 1
                    ].id
                : this.storefrontStorefront.type === "b2b"
                ? this.product.id
                : this.product.storefront_redeemables[
                    this.product.storefront_redeemables.length - 1
                  ].id,
              user_id: this.model.user_id,
            },
          })
          .then(({ data: { priceReviewLines } }) => {
            this.priceReview = priceReviewLines;
          })
          .catch((error) => {
            console.log("error", error);
          })
          .finally(() => {
            this.isLoadingPrice = false;
          });
      }
    },
    getPriceTable() {
      if (this.model.faces && this.model.colors) {
        const quotableType =
          this.storefrontStorefront.type === "b2b"
            ? this.activeVariation
              ? "App\\Models\\ProductVariation"
              : "App\\Models\\Product"
            : "App\\Models\\StorefrontRedeemable";
        const quotableId = this.activeVariation
          ? this.storefrontStorefront.type === "b2b"
            ? this.activeVariation.id
            : this.activeVariation.storefront_redeemables[
                this.activeVariation.storefront_redeemables.length - 1
              ].id
          : this.storefrontStorefront.type === "b2b"
          ? this.product.id
          : this.product.storefront_redeemables[
              this.product.storefront_redeemables.length - 1
            ].id;
        const priceTableKey =
          quotableType +
          "_" +
          quotableId +
          "_" +
          this.model.faces +
          "_" +
          this.model.colors +
          "_" +
          this.model.printTechnique;
        if (priceTableKey in this.variationPriceTables) {
          this.priceTables = this.variationPriceTables[priceTableKey];
        } else {
          this.isLoadingPriceTables = true;
          this.axios
            .get("/store/priceTables", {
              params: {
                faces: this.model.faces,
                colors: this.model.colors,
                print_technique: this.model.printTechnique,
                quotable_type: quotableType,
                quotable_id: quotableId,
                user_id: this.model.user_id,
              },
            })
            .then(({ data }) => {
              this.priceTables = data;
              this.variationPriceTables[priceTableKey] = data;

              if (!this.model.shipping_method) {
                this.model.shipping_method = Object.keys(data)[0];
              }
            })
            .catch((error) => {
              console.log("error", error);
            })
            .finally(() => {
              this.isLoadingPriceTables = false;
            });
        }
      }
    },
    debouncedGetPriceTable() {
      this.quotedPrices = null;
      if (this.quantityTimer) {
        clearTimeout(this.quantityTimer);
      }
      this.getPriceTable();
      this.quantityTimer = setTimeout(() => {
        this.getPrice();
      }, 1000);
    },
  },

  watch: {
    quantity: function () {
      if (this.storefrontStorefront.type === "b2b") {
        this.quotedPrices = null;
        if (this.quantityTimer) {
          clearTimeout(this.quantityTimer);
        }
        this.quantityTimer = setTimeout(() => {
          this.getPrice();
        }, 1000);
      }
    },
    priceTableWatchableInfo: {
      deep: true,
      handler() {
        if (
          this.storefrontStorefront.type === "b2b" &&
          (this.product.variations.length === 0 ||
            (this.product.variations.length !== 0 &&
              this.activeVariation &&
              this.activeVariation.stock))
        )
          this.debouncedGetPriceTable();
      },
    },
    userId: function () {
      if (
        this.storefrontStorefront.type === "b2b" &&
        (this.product.variations.length === 0 ||
          (this.product.variations.length !== 0 &&
            this.activeVariation &&
            this.activeVariation.stock))
      )
        this.debouncedGetPriceTable();
    },
  },
};
</script>

<style>
.watermark {
  opacity: 0.2;
}
</style>
